    #feed {
      display: flex;
      width: 100%;
      max-width: rem(600);
      margin: 0 auto;
      flex-flow: row wrap;
      justify-content: center;
      position: relative;
      z-index: 1;
  
      > div {
        flex-basis: 31%;
        margin: 1%;
        position: relative;
  
        @media screen and (max-width: 800px) {
          flex-basis: 48%;
          &:nth-last-child(3), &:nth-last-child(2){
            display: none;
          }
        }
        a {
          background-repeat: no-repeat;
          background-size: cover;
          display: block;
          overflow: hidden;
          padding-bottom: 100%;
          position: relative;
          width: 100%;

          &.button{
            padding: 0 rem(10);
            color: $primary-color-l;
            border: 2px solid $primary-color-l;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Nimbus Sans Extd D W05 Bold", sans-serif;
          }
  
          span {
            align-items: center;
            background-color: rgba(0, 0, 0, .7);
            color: $white;
            display: flex;
            font-weight: 300;
            font-size: rem(12);
            justify-content: center;
            line-height: 1.3;
            padding: rem(15);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            transform: translateX(-100%);
            transition: transform .3s;
          }
  
          &:active,
          &:focus,
          &:hover {
            span {
              transform: translateX(0);
            }
          }
        }
      }
    }

  