@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "includes/feed";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $primary-color;
    color: $primary-color-d;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    text-transform: uppercase;
}

@media screen and (max-width: 900px){
    video.desktop{
        display: none;
    }
}

@media screen and (min-width: 901px){
    video.mobile{
        display: none;
    }
}

a{
    color: inherit;
    position: relative;
    display: inline-block;
    @include hover{
        transform: scale(1.1);
    }
}
.container{
    max-width: rem(1400);
}

h2{
    font-size: rem(30);
    letter-spacing: 1.2em;
    margin-right: -1.2em;
    @media screen and (max-width: 800px){
        font-size: rem(26);
        letter-spacing: .8em;
        margin-right: -.8em;
    }
}

#header{
    position: relative;
    background-color: $black;
    height: 100vh;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    
    video{
        position: absolute;
        min-width: 100vw;
        min-height: 100vh;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        z-index: 1;
    }

    .container{
        text-align: center;
        position: relative;
        z-index: 10;
        max-width: rem(610);
        img{
            width: 100%;
        }
        @media screen and (max-width: 400px){
            max-width: rem(280);
        }
    }

    h1{
        font-family: "Nimbus Sans Extd D W05 Black", sans-serif;
        font-size: rem(82);
        color: rgba(255,255,255,.6);
        line-height: 1;
        
        @media screen and (max-width: 600px){
            font-size: rem(46);
        }
    }

    .btn{
        // margin-top: rem(20);
        font-size: rem(18);
        border-bottom: 2px solid;
        display: inline-block;
        padding: 0;
        color: rgba(255,255,255,.6);
        @media screen and (max-width: 600px){
            font-size: rem(16);
            display: inline;
        }
    }
}

#main{
    .title{
        font-size: rem(18);
        margin: 0;
        font-family: "Nimbus Sans Extd D W05 Bold", sans-serif;
        
        @media screen and (max-width: 600px){
            font-size: rem(16);
        }
        span{
            display: inline-block;
        }
    }

    .btn{
        margin-top: rem(20);
        font-size: rem(18);
        border-bottom: 2px solid;
        display: inline-block;
        padding: 0;
        
        @media screen and (max-width: 600px){
            font-size: rem(16);
        }
    }
}

#music{
    padding: rem(60) 0 rem(50);
    @media screen and (max-width: 600px){
        padding: rem(40) 0 rem(95);
    }
    text-align: center;
    h2{
        padding-bottom: rem(30);
    }
    .item{
        display: inline-block;
        width: 100%;
        max-width: rem(295);
        margin: rem(10) rem(40);
        
        @media screen and (max-width: 600px){
            margin: 0;
            &+.item{
                margin-top: rem(30);
            }
        }        
        img{
            display: block;
            margin: 0 auto rem(10);
        }
    }

    .more{
        margin-top: rem(40);
        
        @media screen and (max-width: 600px){
            margin-top: rem(25)
        }
    }
}

#video{
    display: flex;
    @media screen and (max-width: 600px){
        flex-direction: column;
        video.mobile{
            display: block;
        }
    }
    >div{
        flex: 1;
        position: relative;
    }
    video{
        width: 100%;
        height: auto;
    }

    .content{
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        padding: 0 rem(10);
        transform: translateY(-50%);
    }
    .btn{
        font-size: rem(16);
    }
}

#follow{
    // padding-top: rem(40);
    .container{
        display: flex;
        align-items: center;
        text-align: center;
        @media screen and (max-width: 1160px){
            .photos{
                display: none;
            }
        }
        >div{
            flex: 1;
        }
        p{
            color: $primary-color-l;
            font-family: "Nimbus Sans Extd D W05 Bold", sans-serif;
        }
    }
    .social{
        margin: rem(25) rem(10) rem(10);
        display: block;
        li{
            display: inline-block;
            &+li{
                margin-left: .5em;
            }
        }
        a{
            i{
                transition: all .3s;
            }
            @include hover{
                i{transform: scale(1.3);}
            }
        }
            font-size: rem(20);
    }

    .newsletter{
        margin: rem(10);
        display: block;
        input, button{
            text-transform: uppercase;
            padding: rem(4) rem(8);
            
            font-size: rem(13);
            color: $primary-color-l;
            letter-spacing: .05em;
            font-family: "Nimbus Sans Extd D W05 Bold", sans-serif;
        }

        input{
            border-bottom: 2px solid $primary-color-l;
            &::placeholder{
                color: $primary-color-l;
            }
        }

        button{
            border: 2px solid $primary-color-l;
            padding: rem(4) rem(5);
            @include hover{
                transform: scale(1.1);
            }
        }
    }

    .photos{
        position: relative;
        min-height: rem(500); 
        .img2{
            position: absolute;
            left: 20%;
            top: 0;
        }
        .img1{
            position: absolute;
            left: 60%;
            top: 10%;
        }
    }
}

#footer{
    padding-top: rem(90);
    text-align: center;
    @media screen and (max-width: 600px){
        padding-top: rem(80);
    }

    .contact-link{
        font-family: "Nimbus Sans Extd D W05 Bold", sans-serif;
        
        border-bottom: 2px solid;
        display: inline;
        padding: 0;
        
        @media screen and (max-width: 359px){
            font-size: rem(14);
        }

    }

    
    .logos{
        margin-top: rem(40);
        @media screen and (max-width: 600px){
            margin-top: rem(70)
        }
        img{
            height: rem(10);
            width: auto;
            margin: 0 rem(10);
            display: inline-block;
            vertical-align: middle;
        }
    }

    .copyright{
        margin-top: .5em;
        font-size: rem(8); 
        color: $gray;
        text-transform: uppercase;
    }
}

#contact{
    background-color: $primary-color;
    width: 100%;
    max-width: rem(500);
    margin: rem(30) auto;
    position: relative;
    padding: rem(30);
    text-align: center;

    .mfp-close{
        color: $primary-color-d;
    }

    h2{
        margin-top: rem(16); 
    }
    .contact{
        color: $primary-color-l;
        font-family: "Nimbus Sans Extd D W05 Bold", sans-serif; 
        font-size: rem(16);
        line-height: 1.2;
        text-transform: uppercase;
    }
}