//settings
$fa-font-path: "../font";

// Colors //
$black: #151515;
$white: #fff;

$primary-color: #ebf3ff;
// $primary-color-l: #b7c3d3;
// $primary-color-d: #727b95; 
$primary-color-d: #656D86; 
$primary-color-l: $primary-color-d;
$gray: #6d6d6d;

//Fonts
$primary-font: "Nimbus Sans Extd D W05 Regular", sans-serif;
